import React from "react";
import Container from "../Container";
import { Typography, Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import {
  AlarmOnRounded,
  AssessmentOutlined,
  AssignmentRounded,
  AutoModeOutlined,
  CableOutlined,
  ChatRounded,
  EditNoteRounded,
  InsightsOutlined,
  MenuBookRounded,
  QuestionAnswerRounded,
  ReportOutlined,
  SearchRounded,
  TimelineRounded,
  TroubleshootRounded,
} from "@mui/icons-material";
import { GradientIconLight } from "../GradientIcon";

function OurOfferings() {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const features = [
    {
      title: "Connect Your Data Universe",
      subtitle:
        "Seamlessly integrate information from ERP systems, documents like SOPs, Excel Sheets, P&IDs, maintenance logs, and real-time systems like DCS - all in one place.",
      image: "/our_offerings/connect.webp",
      icon: CableOutlined,
      // chips: [
      //   {
      //     icon: MenuBookRounded,
      //     label: "Understand",
      //     position: {
      //       top: 24,
      //       right: 0,
      //       transform: `translateX(${isXS ? 20 : 70}%)`,
      //     },
      //   },
      //   {
      //     icon: SearchRounded,
      //     label: "Search",
      //     position: { bottom: 32, left: 0, transform: "translateX(-20%)" },
      //   },
      // ],
    },
    {
      title: "Automate Daily Workflows",
      subtitle:
        "Turn time-consuming tasks into one-click actions. From permit preparation to report generation, let AI handle the routine while your team focuses on what matters.",
      image: "/our_offerings/automate.webp",
      icon: AutoModeOutlined,
      // chips: [
      //   {
      //     icon: TroubleshootRounded,
      //     label: "Analyse",
      //     position: {
      //       top: 24,
      //       right: 0,
      //       transform: `translateX(${isXS ? 20 : 70}%)`,
      //     },
      //   },
      //   {
      //     icon: QuestionAnswerRounded,
      //     label: "Ask",
      //     position: { bottom: 32, left: 0, transform: "translateX(-20%)" },
      //   },
      // ],
    },
    {
      title: "Ask Questions, Get Insights",
      subtitle:
        "Ask questions in plain language and get data-backed answers. From troubleshooting guidance to performance trends, turn your data into actionable intelligence.",
      image: "/our_offerings/ask.webp",
      icon: InsightsOutlined,
      // chips: [
      //   {
      //     icon: MenuBookRounded,
      //     label: "Understand",
      //     position: {
      //       top: 24,
      //       right: 0,
      //       transform: `translateX(${isXS ? 20 : 70}%)`,
      //     },
      //   },
      //   {
      //     icon: SearchRounded,
      //     label: "Search",
      //     position: { bottom: 32, left: 0, transform: "translateX(-20%)" },
      //   },
      // ],
    },
    {
      title: "Generate Reports",
      subtitle:
        "Generate reports, complete documents, identify patterns to make better decisions.",
      image: "/our_offerings/generate.webp",
      icon: AssessmentOutlined,
      // chips: [
      //   {
      //     icon: AssignmentRounded,
      //     label: "Reports",
      //     position: {
      //       top: 24,
      //       right: 0,
      //       transform: `translateX(${isXS ? 20 : 70}%)`,
      //     },
      //   },
      //   {
      //     icon: AlarmOnRounded,
      //     label: "Quick Turnaround",
      //     position: { bottom: 32, left: 0, transform: "translateX(-20%)" },
      //   },
      // ],
    },
  ];

  return (
    <Container>
      <Typography variant="overline" sx={{ color: "#9C27B0" }}>
        What Raven Does
      </Typography>
      <Box mb={2} />
      <Typography variant="h4">Our Solution: AI Assistants for Every Task</Typography>
      {/* <Box mb={2} />
      <Typography fontWeight={500}>
        Deploy an on-demand army of AI agents, ready for your team's command
      </Typography> */}
      <Box mb={5} />
      <Box width={"100%"}>
        {features.map((item, i) => {
          const { title, subtitle, image, chips, icon } = item;
          return (
            <>
              <Box
                key={i}
                px={{ xs: 2, md: 4 }}
                pt={{ xs: 2, md: 4 }}
                sx={{
                  borderRadius: 8,
                  border: `1px solid ${theme.palette.divider}`,
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Box display="flex" mb={2}>
                  <GradientIconLight Icon={icon} sx={{ fontSize: 28 }} />
                </Box>
                <Typography variant="h5" fontWeight={600}>
                  {title}
                </Typography>
                <Box mb={1} />
                <Typography fontWeight={500} color={"textSecondary"}>
                  {subtitle}
                </Typography>
                <Box
                  display={"flex"}
                  height={"100%"}
                  mt={8}
                  alignItems={"flex-end"}
                  justifyContent={"center"}
                >
                  <img
                    src={image}
                    alt={title}
                    style={{
                      maxWidth: i === 0 ? 496 : 620,
                      width: "100%",
                      display: "block",
                    }}
                  />
                </Box>
              </Box>
              {i !== features.length - 1 && <Box mb={{ xs: 4, md: 7.5 }} />}
            </>
          );
        })}
      </Box>
    </Container>
  );
}

export default OurOfferings;
