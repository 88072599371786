import React from "react";
import Container from "../Container";
import { Typography, Box, Button, useTheme, useMediaQuery, Grid } from "@mui/material";
import GradientBorderButtonWrapper from "../GradientBorderButtonWrapper";
import { makeStyles } from "tss-react/mui";
import { usePostHog } from "posthog-js/react";
import { CLICK_SCHEDULE_DEMO_BTN } from "../posthogEvents";
import { useFormProvider } from "../../providers/FormProvider";
import TalkToUsButton from "../TalkToUsButton";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: "linear-gradient(306deg, #BF40AE 41.62%, #6B40BF 70.81%, #4E40BF 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function Hero({ title1, title2, subtitle1, subtitle2, bg = "/general/hero_bg.mp4" }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const posthog = usePostHog();
  const { handleOpenForm } = useFormProvider();
  const isVideo = bg.endsWith(".mp4");

  const handleCTAClick = () => {
    handleOpenForm();
    posthog.capture(CLICK_SCHEDULE_DEMO_BTN);
  };

  return (
    <Box
      px={{ md: 20, xs: 2 }}
      py={{ md: 15, xs: 6 }}
      sx={{ marginTop: { xs: "72.5px", md: "74.5px" } }}
      position={"relative"}
    >
      {isVideo ? (
        <video
          autoPlay
          loop
          muted
          playsInline
          className="video-element"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        >
          <source src={bg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <Box
          component="img"
          src={bg}
          alt="hero background"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -2,
            height: "100%",
            width: "100%",
            objectFit: "cover",
            opacity: 0.5, // Added opacity to make image whiter/more transparent
          }}
        />
      )}
      <Box width={"100%"} maxWidth={992} margin={"0 auto"}>
        <Grid container alignItems={"center"}>
          <Grid item xs={12} md={8.5}>
            <Typography
              sx={{
                typography: { xs: "h3", md: "h3" },
              }}
              component={"h1"}
            >
              {title1}
            </Typography>
            <Typography
              sx={{
                typography: { xs: "h3", md: "h3" },
              }}
              component={"h1"}
            >
              <span className={classes.gradientText}>{title2}</span>
            </Typography>
            <Box mb={{ xs: 2, md: 3 }} />
            <Typography
              textAlign={"start"}
              sx={{
                typography: { xs: "h6", md: "h6" },
                position: "relative",
                "& .underline": {
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: "-4px",
                    left: 0,
                    width: "100%",
                    height: "3px",
                    backgroundColor: "#FFE44D",
                  },
                },
              }}
              component={"h2"}
            >
              <span>{subtitle1 + " "}</span>
              <span className="underline">{subtitle2}</span>
            </Typography>
            <Box mb={{ xs: 4, md: 6 }} />
            <TalkToUsButton onClick={handleCTAClick} />
          </Grid>
          {!isXS && (
            <Grid item xs={12} md={3.5}>
              <img
                src="/yy3.gif"
                alt="hero animation"
                style={{
                  width: "100%",
                }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default Hero;
