import React from "react";
import Container from "../../components/Container";
import { Typography, Box, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import TalkToUsButton from "../../components/TalkToUsButton";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function CTA({ title, subtitle, btnText = "Book a Demo", onClick = () => {} }) {
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <Container>
      <Box display="flex" justifyContent="center" width={"100%"}>
        <Box
          px={10}
          py={15}
          borderRadius={8}
          sx={{
            background: "linear-gradient(104deg, #FFF 1.1%, #FCF7FF 98.41%)",
            boxShadow: "2px 2px 10px 0px rgba(169, 61, 253, 0.16)",
            maxWidth: "800px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              typography: "h4",
              width: "100%",
            }}
            textAlign={"center"}
          >
            <span className={classes.gradientText}>{title}</span>
          </Typography>
          <Box mb={2} />
          <Typography variant="h6" textAlign={"center"}>
            {subtitle}
          </Typography>
          <Box mb={6} />
          <Box display={"flex"} justifyContent={"center"}>
            <TalkToUsButton onClick={onClick} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default CTA;
