import React from "react";
import Container from "../../components/Container";
import { Typography, Box, IconButton, Grid, useMediaQuery } from "@mui/material";
import { CheckCircleRounded } from "@mui/icons-material";
import { GradientIconLight } from "../../components/GradientIcon";

const securityItems = [
  {
    key: 1,
    label: "End-to-end encryption and role-based access control for data protection",
    icon: <GradientIconLight Icon={CheckCircleRounded} />,
  },
  {
    key: 2,
    label: "SOC2 compliant, CCPA and GDPR ready, pursuing ISO 27001 certification",
    icon: <GradientIconLight Icon={CheckCircleRounded} />,
  },
  {
    key: 3,
    label: "Strict no-retention policy for third-party AI services",
    icon: <GradientIconLight Icon={CheckCircleRounded} />,
  },
  {
    key: 4,
    label: "Regular security audits, penetration testing, and compliance checks",
    icon: <GradientIconLight Icon={CheckCircleRounded} />,
  },
];

function Security() {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Container>
      <Grid container alignItems={"center"} spacing={2.5}>
        <Grid item md={8}>
          <Typography variant="overline" sx={{ color: "#9C27B0" }}>
            Security
          </Typography>
          <Box mb={2} />
          <Typography variant="h4">Future Ready Enterprise-Grade Security</Typography>
          <Box mb={5} />
          <Box>
            {securityItems.map((securityItem, i) => {
              const { key, icon, label } = securityItem;
              return (
                <Box key={key} display={"flex"} alignItems={"center"} py={1}>
                  <IconButton
                    sx={{
                      background:
                        "linear-gradient(140deg, rgba(215, 213, 213, 0.08) 14.9%, rgba(255, 255, 255, 0.08) 38.71%, rgba(33, 36, 38, 0.08) 71.41%)",
                    }}
                  >
                    <GradientIconLight Icon={CheckCircleRounded} sx={{ fontSize: 24 }} />
                  </IconButton>
                  <Box mr={2} />
                  <Typography fontWeight={500}>{label}</Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
        {!isXS && (
          <Grid item md={4} display={"flex"} justifyContent={"end"}>
            <img
              src="/general/security.webp"
              alt="security"
              width={262}
              style={{ borderRadius: 16 }}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default Security;
