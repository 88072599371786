import React from "react";
import { Button } from "@mui/material";
import { CallRounded } from "@mui/icons-material";

function TalkToUsButton({ onClick, text = "Talk to us" }) {
  return (
    <Button size="large" variant="contained" startIcon={<CallRounded />} onClick={onClick}>
      {text}
    </Button>
  );
}

export default TalkToUsButton;
