import React from "react";
import { Grid, Card, CardMedia, Box, Typography, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils";
import { makeStyles } from "tss-react/mui";
import { BLOG_DETAIL_URL } from "./Blogs";

const useStyles = makeStyles()((theme) => ({
  text: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
}));

function FirstBlog({ firstBlog }) {
  const navigate = useNavigate();
  const { classes } = useStyles();
  return (
    <Grid
      container
      spacing={6}
      sx={{
        cursor: "pointer",
        "&:hover .blog-cover-image": {
          transform: "scale(1.1)",
        },
      }}
      onClick={() =>
        window.open(
          `https://blog.startraven.com/view/${firstBlog.slug}`,
          "_blank"
        )
      }>
      <Grid item md={7}>
        <Card elevation={0} sx={{ borderRadius: 8, overflow: "hidden" }}>
          <CardMedia
            component="img"
            width="100%"
            image={firstBlog.cover_image}
            alt={firstBlog.title}
            className="blog-cover-image"
            sx={{
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </Card>
      </Grid>
      <Grid item md={5}>
        <Box
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}>
          <Typography
            className={classes.text}
            variant="h4"
            sx={{ fontWeight: 600, WebkitLineClamp: "3 !important" }}
            lineHeight={"normal"}
            gutterBottom>
            {firstBlog.title}
          </Typography>
          <Typography
            color={"textSecondary"}
            className={classes.text}
            gutterBottom>
            {firstBlog.excerpt}
          </Typography>
          <Box mt={1}>
            <Typography variant="body2">{firstBlog.author}</Typography>
            <Typography variant="body2" color={"textSecondary"} gutterBottom>
              {formatDate(firstBlog.created_at)}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default FirstBlog;
