import React from "react";
import { Box } from "@mui/material";
import { useFormProvider } from "../../providers/FormProvider";
import CTA from "../../components/Home/CTA";
import Security from "../../components/Home/Security";
import Hero from "../../components/CaseStudy/Hero";
import Content from "../../components/CaseStudy/Content";
import HowItWorks from "../../components/Home/HowItWorks";
function CaseStudy() {
  const { handleOpenForm } = useFormProvider();
  return (
    <Box>
      <Hero />
      <Content />
      <HowItWorks />
      <CTA
        title={"Start with a Pilot Project"}
        subtitle={`Join industry leaders transforming their operations with Raven.`}
        onClick={handleOpenForm}
      />
    </Box>
  );
}

export default CaseStudy;
