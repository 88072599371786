import React from "react";
import Container from "../Container";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

function Metrics() {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const metrics = [
    {
      title: isXS ? (
        "2.4x more accurate"
      ) : (
        <>
          2.4x more <br /> accurate
        </>
      ),
      subtitle: (
        <>
          Accurate answers with our AI compared to ChatGPT for manufacturing
          queries.
        </>
      ),
    },
    {
      title: (
        <>
          97% <br /> preferred
        </>
      ),
      subtitle:
        "Raven answers are preferred 97% by operators when compared to ChatGPT.",
    },
    {
      title: (
        <>
          40+ <br />
          years
        </>
      ),
      subtitle: "More than 40 years of total industry experience",
    },
  ];
  return (
    <Container
      wrapperStyle={{
        background: "linear-gradient(90deg, #F9F9FF 50%, #FFF 100%)",
      }}>
      <Typography variant="overline" sx={{ color: "#9C27B0" }}>
        How it works
      </Typography>
      <Box mb={2} />
      <Typography variant="h4">Getting Started with Raven</Typography>
      <Box mb={5} />
      <img
        src="/our_offerings/data_source_diagram.webp"
        alt="data_source"
        width={"100%"}
      />
      {/* <Box
        gap={4}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "start", md: "center" }}
        justifyContent={"space-between"}
        mt={{ md: 15, xs: 6 }}>
        {metrics.map((metric, i) => {
          const { title, subtitle } = metric;
          return (
            <Box key={i}>
              <Typography
                sx={{
                  typography: { xs: "h4", md: "h3" },
                }}
                component={"h3"}>
                {title}
              </Typography>
              <Box mb={2} />
              <Typography fontWeight={500}>{subtitle}</Typography>
            </Box>
          );
        })}
      </Box> */}
    </Container>
  );
}

export default Metrics;
