import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Box,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Container from "../../components/Container";
import { makeStyles } from "tss-react/mui";
import axios from "axios";
import { formatDate } from "../../utils";
import FirstBlog from "./FirstBlog";

export const BLOG_DETAIL_URL =
  "https://raven-blog-editor-readonly-staging.vercel.app/view";

const useStyles = makeStyles()((theme) => ({
  text: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
}));

function Blogs() {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const { classes } = useStyles();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        "https://api.ravenapp.dev/copilot/v1/api/published_blogs"
      );
      setBlogs(response.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  if (!loading && !blogs) {
    return (
      <Container
        wrapperStyle={{
          marginTop: isXS ? 72.5 : 74.5,
        }}>
        <Typography variant="h5">Coming Soon...</Typography>
      </Container>
    );
  }

  return (
    <Container
      wrapperStyle={{
        marginTop: isXS ? 72.5 : 74.5,
      }}>
      {blogs.length > 0 && <FirstBlog firstBlog={blogs[0]} />}
      {blogs.length > 1 && (
        <>
          <Box mb={6} />
          <Grid container spacing={4}>
            {blogs.slice(1).map((post) => (
              <Grid
                item
                xs={12}
                md={4}
                key={post.id}
                sx={{
                  cursor: "pointer",
                  "&:hover .blog-cover-image": {
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() =>
                  window.open(
                    `https://blog.startraven.com/view/${post.slug}`,
                    "_blank"
                  )
                }>
                <Card
                  elevation={0}
                  sx={{ overflow: "hidden", borderRadius: 6 }}>
                  <CardMedia
                    className="blog-cover-image"
                    sx={{
                      borderRadius: 6,
                      transition: "transform 0.3s ease-in-out",
                    }}
                    component="img"
                    width={"100%"}
                    image={post.cover_image}
                    alt={post.title}
                  />
                  <CardContent sx={{ p: 0, pt: 3 }}>
                    <Typography
                      variant="h6"
                      className={classes.text}
                      gutterBottom>
                      {post.title}
                    </Typography>
                    <Typography
                      className={classes.text}
                      sx={{ mt: 1 }}
                      color={"textSecondary"}>
                      {post.excerpt}
                    </Typography>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      gap={1}
                      mt={1}>
                      <Typography variant="body2" color={"textSecondary"}>
                        {post.author}
                      </Typography>
                      <Typography variant="caption" color={"textSecondary"}>
                        {formatDate(post.created_at)}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {loading && (
        <Box position={"absolute"} top={"50%"} left={"50%"}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
}

export default Blogs;
