import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Link,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  alpha,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowDropDown, Menu as MenuIcon } from "@mui/icons-material";
import MobileViewMenu from "./MobileViewMenu";
import { useFormProvider } from "../../providers/FormProvider";
import { usePostHog } from "posthog-js/react";
import { CLICK_SCHEDULE_DEMO_BTN, VIEW_BLOG_PAGE, VIEW_FREE_TOOLS_PAGE } from "../posthogEvents";
import { BLOGS_URL, CUSTOMERS_URL, ABOUT_URL } from "../../Routes";
import TalkToUsButton from "../TalkToUsButton";

export const links = [
  { key: "aboutus", label: "About Us", path: ABOUT_URL },
  { key: "blogs", label: "Blogs", path: BLOGS_URL, type: "external" },
  { key: "customers", label: "Customers", path: CUSTOMERS_URL },
];

const useStyles = makeStyles()((theme) => ({
  container: {
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    background: "rgba(255, 255, 255, 0.85)",
    boxShadow: "0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset",
    backdropFilter: "blur(20px)",
    zIndex: 999,
  },
  activeLink: {
    textDecoration: "underline",
  },
  tooltip: {
    maxWidth: 600,
    margin: 0,
    padding: 0,
    background: "transparent",
  },
  tooltipPlacementBottom: {
    marginTop: "0px !important",
  },
  paper: {
    borderRadius: 12,
    background: alpha(theme.palette.background.dark, 0.8),
    boxShadow:
      "0px 11px 15px -7px rgba(0, 0, 0, 0.20), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
    backdropFilter: "blur(20px)",
    border: "1px solid rgba(255, 255, 255, 0.12)",
  },
}));

function Navbar() {
  const { handleOpenForm } = useFormProvider();
  const theme = useTheme();
  const { classes } = useStyles();
  const posthog = usePostHog();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCTAClick = () => {
    handleOpenForm();
    posthog.capture(CLICK_SCHEDULE_DEMO_BTN);
  };

  return (
    <Box px={{ md: 20, xs: 3 }} py={2} className={classes.container}>
      <Box
        maxWidth={1120}
        margin={"0 auto"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Link component={NavLink} to={"/"} underline="none">
          <img
            src={"/logo/logo_color.png"}
            width={108}
            height={34}
            alt="Logo"
            style={{ filter: "brightness(0) invert(0)" }}
          />
        </Link>
        {isXS ? (
          <IconButton onClick={handleOpen}>
            <MenuIcon />
          </IconButton>
        ) : (
          <Box display={"flex"} alignItems={"center"}>
            {links.map((link, i) => {
              return <NavItem key={i} navItem={link} />;
            })}
            <TalkToUsButton onClick={handleCTAClick} />
          </Box>
        )}
      </Box>
      {open && <MobileViewMenu onClose={handleClose} handleCTAClick={handleCTAClick} />}
    </Box>
  );
}

function NavItem({ navItem }) {
  const location = useLocation();
  const { classes } = useStyles();
  const posthog = usePostHog();
  const theme = useTheme();
  const { label, path, children, type } = navItem;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isCurrentPath = location.pathname.startsWith(path);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLink = (link) => {
    const { key, path, type } = link;
    if (type === "internal") {
      navigate(path);
    } else {
      window.open(path, "_blank");
      if (key === "blog") {
        posthog.capture(VIEW_BLOG_PAGE);
      } else if (key === "free_tools") {
        posthog.capture(VIEW_FREE_TOOLS_PAGE);
      }
    }
    handleClose();
  };

  return (
    <Box>
      {children ? (
        <>
          <Button
            onClick={handleOpen}
            variant="text"
            endIcon={<ArrowDropDown fontSize="small" />}
            sx={{
              textTransform: "initial",
              marginRight: 2,
              color: "rgba(255, 255, 255, 0.6)",
              "&:hover": {
                background: "transparent",
              },
            }}
          >
            {label}
          </Button>
          <Menu
            classes={{ paper: classes.paper }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ horizontal: "center" }}
          >
            {children.map((link) => {
              const { key, label, icon } = link;
              return (
                <MenuItem
                  key={key}
                  sx={{
                    color: "white",
                    paddingY: 1.5,
                    "&:hover": {
                      background: alpha(theme.palette.background.dark, 0.6),
                    },
                  }}
                  onClick={() => handleClickLink(link)}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{label}</ListItemText>
                </MenuItem>
              );
            })}
          </Menu>
        </>
      ) : (
        <NavLink to={path} target={type === "external" ? "_blank" : undefined}>
          <Button
            variant="text"
            sx={{
              textTransform: "initial",
              color: isCurrentPath ? theme.palette.text.primary : theme.palette.action.active,
              marginRight: 2,
              fontWeight: isCurrentPath ? 600 : 400,
              background: isCurrentPath
                ? "linear-gradient(292deg, rgba(115, 38, 104, 0.12) 0.1%, rgba(64, 38, 115, 0.12) 50.05%, rgba(47, 38, 115, 0.12) 100%)"
                : "transparent",
              borderRadius: "8px",
              padding: "6px 16px",
              "&:hover": {
                background:
                  "linear-gradient(292deg, rgba(115, 38, 104, 0.16) 0.1%, rgba(64, 38, 115, 0.16) 50.05%, rgba(47, 38, 115, 0.16) 100%)",
              },
            }}
          >
            {label}
          </Button>
        </NavLink>
      )}
    </Box>
  );
}

export default Navbar;
