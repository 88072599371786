import React, { useState, useRef, useCallback, useEffect } from "react";
import Container from "../Container";
import {
  Typography,
  Box,
  IconButton,
  useMediaQuery,
  Grid,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import {
  BoltRounded,
  DashboardRounded,
  PrecisionManufacturingRounded,
  TaskRounded,
  FindInPageRounded,
  GroupsRounded,
  SettingsSuggestRounded,
  SchoolRounded,
  SearchOffRounded,
  ScheduleRounded,
  SlowMotionVideoRounded,
  SyncProblemRounded,
  AccountTreeRounded,
  ScatterPlotRounded,
  LockOutlined,
  WarningRounded,
  CompareArrowsRounded,
  PeopleAltRounded,
  PublishedWithChangesRounded,
  TrendingDownRounded,
} from "@mui/icons-material";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import { useTheme } from "@mui/material/styles";
import { GradientIconLight } from "../GradientIcon";
import { styled } from "@mui/material/styles";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const data = [
  {
    title: "10-15% OEE Improvement",
    icon: PrecisionManufacturingRounded,
  },
  { title: "2%-10% Asset Uptime Improvement", icon: TrendingUpRoundedIcon },
  { title: "40% Faster Onboarding & Training", icon: SchoolRounded },
];

const FlowCard = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "12px",
  overflow: "hidden",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  transition: "all 0.1s ease",
}));

const flowData = [
  {
    title: "Scattered Information",
    subtitle: "Can't find it",
    description:
      "Critical information spread across multiple systems and locations, making it difficult to access the right information at the right time. This leads to inefficiencies and missed opportunities.",
    icon: ScatterPlotRounded,
    image: "/icons/scattered.webp",
    details: {
      icon: SearchOffRounded,
      title: "Hard-to-Find Critical Data",
      points: [
        "Time wasted in searching",
        "Routine tasks consume limited bandwidth",
      ],
    },
    metrics: {
      icon: ScheduleRounded,
      title: "Lost Time",
      metric: "2-4 hrs",
      subtitle: "Per Incident due to delayed decisions",
      points: [""],
    },
  },
  {
    title: "Knowledge Silos",
    subtitle: "Can't share it",
    description:
      "Expertise trapped within departments and individuals, preventing effective collaboration and knowledge sharing across the organization.",
    icon: AccountTreeRounded,
    image: "/icons/silos.webp",
    details: {
      icon: LockOutlined,
      title: "Expertise Remains Trapped",
      points: [
        "Valuable knowledge stays inaccessible",
        "Teams reinvent the wheel over and over again",
      ],
    },
    metrics: {
      icon: WarningRounded,
      title: "Safety Risk",
      metric: "↑ 40%",
      subtitle: "High chances of incidents",
      points: [
        "Higher incident probability",
        "Repeated past mistakes",
        "Missing critical checks",
      ],
    },
  },
  {
    title: "Inconsistent Execution",
    subtitle: "Can't standardize it",
    description:
      "Varying processes and methods leading to quality issues, inefficiencies, and inconsistent results across teams and locations.",
    icon: SyncProblemRounded,
    image: "/icons/execution.webp",
    details: {
      icon: CompareArrowsRounded,
      title: "Different approaches across shifts",
      points: ["Variable adherence of processes", "Quality fluctuates"],
    },
    metrics: {
      icon: PeopleAltRounded,
      title: "Staff Burnout",
      metric: "60%",
      subtitle: "Time spent on routine tasks by experts",
      points: ["Expert burnout", "Constant interruptions"],
    },
  },
  {
    title: "Slow Knowledge Transfer",
    subtitle: "Can't scale it",
    description:
      "Inefficient knowledge transfer and training processes slowing down growth, adaptation, and improvement across the organization.",
    icon: SlowMotionVideoRounded,
    image: "/icons/learning.webp",
    details: {
      icon: PublishedWithChangesRounded,
      title: "Hard to scale expertise",
      points: [
        "12-18 months learning curve for new engineers",
        "Dependence on experts for routine decision making",
      ],
    },
    metrics: {
      icon: TrendingDownRounded,
      title: "Efficiency Drop",
      metric: "$1M+",
      subtitle: "Annual Productivity Loss",
      points: [
        "12-18 month learning curve",
        "High training costs",
        "Expert dependency",
      ],
    },
  },
];

function Benefits() {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedCard, setSelectedCard] = useState(0);

  // Create refs array before using map
  const sectionRefs = useRef(flowData.map(() => React.createRef()));

  useEffect(() => {
    const handleScroll = () => {
      // Get all sections
      const sections = sectionRefs.current.map((ref) => ref.current);

      // Find the section closest to top of viewport
      const distances = sections.map((section) => {
        const rect = section.getBoundingClientRect();
        return Math.abs(rect.top);
      });

      // Get index of section with smallest distance to top
      const closestIndex = distances.indexOf(Math.min(...distances));
      setSelectedCard(closestIndex);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Initial check
    handleScroll();

    // Cleanup
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Update the card click handler in both mobile and desktop views
  const handleCardClick = (index) => {
    setSelectedCard(index);
    // Scroll to corresponding section when card is clicked
    if (sectionRefs.current[index]?.current) {
      const element = sectionRefs.current[index].current;
      const y = element.getBoundingClientRect().top + window.scrollY - 100; // -100 for header offset
      window.scrollTo(0, y); // Using direct scrollTo without behavior option for instant scroll
    }
  };

  return (
    <Container>
      <Typography variant="overline" sx={{ color: "#9C27B0" }}>
        Problems & Their Business Impact
      </Typography>
      <Box mb={2} />
      <Typography
        variant="h4"
        sx={{
          textAlign: "start",
        }}>
        The Crisis today in Operations, Maintenance & Engineering
      </Typography>
      <Box mb={6} />
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        gap={5}
        sx={{ position: "relative" }}>
        {isXS ? (
          <Box
            p={1}
            width={"100%"}
            display={"flex"}
            overflow={"scroll"}
            gap={2}
            sx={{ position: "sticky", top: 72, height: 82 }}>
            {flowData.map((item, index) => (
              <FlowCard
                key={index}
                onClick={() => handleCardClick(index)}
                sx={{
                  minWidth: 250,
                  // height: "auto",
                  // minHeight: "80px",
                  cursor: "pointer",
                  transform:
                    selectedCard === index ? "scale(1.02)" : "scale(1)",
                  border:
                    selectedCard === index
                      ? `2px solid ${theme.palette.accent}`
                      : "none",
                  position: "relative",
                  backgroundColor:
                    selectedCard === index ? "#F3E5F5" : "background.paper",
                  "&:hover": {
                    transform:
                      selectedCard === index ? "scale(1.02)" : "scale(1.01)",
                    boxShadow: (theme) => theme.shadows[4],
                  },
                  transition: "all 0.2s ease-in-out",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                  }}>
                  <GradientIconLight Icon={item.icon} sx={{ fontSize: 20 }} />
                  <Box ml={1}>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ mt: 1 }}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                </Box>
              </FlowCard>
            ))}
          </Box>
        ) : (
          <Stack
            spacing={3}
            sx={{ position: "sticky", width: "30%", top: 100, height: 500 }}>
            {flowData.map((item, index) => (
              <FlowCard
                key={index}
                onClick={() => handleCardClick(index)}
                sx={{
                  width: "100%",
                  // height: "auto",
                  minHeight: "85px",
                  mt: 4,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  cursor: "pointer",
                  transform:
                    selectedCard === index ? "scale(1.02)" : "scale(1)",
                  border:
                    selectedCard === index
                      ? `2px solid ${theme.palette.accent}`
                      : "none",
                  position: "relative",
                  backgroundColor:
                    selectedCard === index ? "#F3E5F5" : "background.paper",
                  "&:hover": {
                    transform:
                      selectedCard === index ? "scale(1.02)" : "scale(1.01)",
                    boxShadow: (theme) => theme.shadows[4],
                  },
                  transition: "all 0.2s ease-in-out",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    px: 2,
                    py: 1.5,
                  }}>
                  <GradientIconLight Icon={item.icon} sx={{ fontSize: 28 }} />
                  <Box ml={3}>
                    <Typography variant="subtitle1" fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                </Box>
              </FlowCard>
            ))}
          </Stack>
        )}
        <Box width={isXS ? "100%" : "60%"} display={"flex"}>
          <Box>
            {flowData.map((item, index) => (
              <Box key={index} ref={sectionRefs.current[index]} mb={10}>
                <Box position="relative">
                  <Box
                    sx={{
                      position: "relative",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: 150,
                        background:
                          "linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%)",
                        borderRadius: 4,
                      },
                    }}>
                    <Typography
                      variant="h5"
                      fontWeight={600}
                      sx={{
                        position: "absolute",
                        bottom: 16,
                        left: 16,
                        color: "white",
                        zIndex: 1,
                      }}>
                      {item.title}
                    </Typography>
                    <img
                      src={item.image}
                      alt={item.title}
                      width="100%"
                      style={{
                        borderRadius: 16,
                        height: 150,
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </Box>
                </Box>
                <Box mb={3} />
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Box
                      height={240}
                      p={2.5}
                      borderRadius={4}
                      sx={{
                        background:
                          "linear-gradient(280deg, rgba(227, 223, 252, 0.50) 0.82%, rgba(254, 254, 255, 0.50) 50.21%, rgba(252, 242, 253, 0.50) 99.6%)",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <GradientIconLight Icon={item.details.icon} sx={{ fontSize: 28 }} />
                        <Box ml={1}>
                          <Typography variant="subtitle1" fontWeight={600}>
                            {item["details"]["title"]}
                          </Typography>
                        </Box>
                      </Box>
                      <Box mb={2} />
                      <ul style={{ margin: 0, paddingLeft: 16 }}>
                        {item["details"]["points"].map((item, index) => (
                          <li key={index} style={{ marginBottom: '8px' }}>
                            <Typography variant="body2">{item}</Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    md={0.5}
                    sx={{ display: { xs: "none", md: "flex" }, justifyContent: "center" }}
                  >
                    <KeyboardDoubleArrowRightIcon
                      sx={{
                        fontSize: 32,
                        color: theme.palette.accent,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={5.5}>
                    <Box
                      height={240}
                      p={2.5}
                      borderRadius={4}
                      sx={{
                        background:
                          "linear-gradient(280deg, rgba(227, 223, 252, 0.50) 0.82%, rgba(254, 254, 255, 0.50) 50.21%, rgba(252, 242, 253, 0.50) 99.6%)",
                      }}>
                      <GradientIconLight Icon={item["metrics"]["icon"]} />
                      <Box mb={1} />
                      <Typography>{item["metrics"]["title"]}</Typography>
                      <Typography variant="h4">
                        {item["metrics"]["metric"]}
                      </Typography>
                      <Typography>{item["metrics"]["subtitle"]}</Typography>
                      <Box mb={1} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
          {!isXS && (
            <Box ml={2} sx={{ position: "sticky", top: 100, height: 200 }}>
              {Array.from({ length: 4 }).map((item, index) => (
                <Box
                  key={index}
                  mb={1}
                  height={index === selectedCard ? 40 : 12}
                  width={8}
                  borderRadius={1}
                  bgcolor={
                    index === selectedCard ? "#9C27B0" : "rgba(0, 0, 0, 0.18)"
                  }
                  sx={{
                    transition: "all 0.3s ease-in-out",
                  }}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default Benefits;
