import React from "react";
import Container from "../Container";
import { Box, Typography } from "@mui/material";
import {
  IntegrationInstructionsRounded,
  ManageSearchRounded,
  RocketLaunchRounded,
  SettingsRounded,
} from "@mui/icons-material";
import { GradientIconLight } from "../GradientIcon";

const data = [
  {
    icon: ManageSearchRounded,
    title: "Discovery",
    description: "We analyze your current workflows and data sources.",
  },
  {
    icon: IntegrationInstructionsRounded,
    title: "Integration",
    description:
      "We connect your documents, images, and ERP data along with other systems and sources to build your data universe.",
  },
  {
    icon: SettingsRounded,
    title: "Configuration",
    description: "We tailor the assistant to your specifications",
  },
  {
    icon: RocketLaunchRounded,
    title: "Deployment",
    description: "We train your team and go live.",
  },
];

function HowItWorks() {
  return (
    <Container>
      <Typography variant="overline" sx={{ color: "#9C27B0" }}>
        How it works
      </Typography>
      <Box mb={2} />
      <Typography variant="h4">Getting Started with Raven</Typography>
      <Box mb={5} />
      {data.map((item, i) => {
        const { icon, title, description } = item;
        return (
          <Box
            key={title}
            p={3}
            mb={i !== data.length - 1 ? 2.5 : 0}
            width={"100%"}
            borderRadius={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              background: "linear-gradient(104deg, #FCF7FF 1.1%, #FFF 98.41%)",
            }}
          >
            <Box display={"flex"}>
              <GradientIconLight Icon={icon} />
              <Box mx={3}>
                <Typography variant="h5" lineHeight={"normal"}>
                  {title}
                </Typography>
                <Box mb={1} />
                <Typography fontWeight={500} color={"text.secondary"}>
                  {description}
                </Typography>
              </Box>
            </Box>
            <Typography variant="h2" sx={{ color: "#F3E5F5" }}>
              {i + 1}
            </Typography>
          </Box>
        );
      })}
    </Container>
  );
}

export default HowItWorks;
