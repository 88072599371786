import * as React from "react";
import Box from "@mui/material/Box";

export default function Container(props) {
  return (
    <Box
      px={{ md: 20, xs: 2 }}
      py={{ md: 15, xs: 6 }}
      style={props.wrapperStyle}>
      <Box
        maxWidth={992}
        margin={"0 auto"}
        display={"flex"}
        flexDirection="column"
        justifyContent={"start"}
        alignItems="start"
        textAlign={"start"}
        style={props.containerStyle}>
        {props.children}
      </Box>
    </Box>
  );
}
