import React from "react";
import Box from "@mui/material/Box";
import Testimonial from "../../components/Home/Testimonial";
import Hero from "../../components/Home/Hero";
import { useFormProvider } from "../../providers/FormProvider";
import Benefits from "../../components/Home/Benefits";
import Usecases from "../../components/Home/Usecases";
import Metrics from "../../components/Home/Metrics";
import OurOfferings from "../../components/Home/OurOfferings";
import CTA from "../../components/Home/CTA";
import Security from "../../components/Home/Security";
import WhyRaven from "../../components/Home/WhyRaven";
import {
  AlarmOnRounded,
  AssignmentRounded,
  MenuBookRounded,
  QuestionAnswerRounded,
  SearchRounded,
  TroubleshootRounded,
  QuickreplyRounded as QuickreplyRoundedIcon,
  Schema as SchemaRoundedIcon,
  RateReview as RateReviewRoundedIcon,
  Assessment as AssessmentRoundedIcon,
  IntegrationInstructions as IntegrationInstructionsIcon,
  ManageSearch as ManageSearchIcon,
  BugReport as BugReportIcon,
  CrisisAlert as CrisisAlertIcon,
  WorkspacePremium as WorkspacePremiumIcon,
  AssignmentTurnedInRounded,
  NotificationsActiveRounded,
  SchoolRounded,
  FindInPageRounded,
  SchemaRounded,
} from "@mui/icons-material";
import HowItWorks from "../../components/Home/HowItWorks";
import IndustriesServed from "../../components/Home/IndustriesServed";

const features = [
  {
    title: "Search & Make Sense of Information Instantly",
    subtitle:
      "Search all your internal data as well as our extensive library of manufacturing resources, including SOPs, manuals, P&IDs, and industry standards.",
    image: "/our_offerings/container_1.svg",
    chips: [
      {
        icon: MenuBookRounded,
        label: "Understand",
        position: { top: 24, right: 0, transform: "translateX(70%)" },
      },
      {
        icon: SearchRounded,
        label: "Search",
        position: { bottom: 32, left: 0, transform: "translateX(-20%)" },
      },
    ],
  },
  {
    title: "Unlock Actionable Insights from Your Data",
    subtitle:
      "Aggregate Insights from Multiple Data Sources for Accurate Answers to Your Most Important Questions, Backed by Auditable Citations",
    image: "/our_offerings/container_2.svg",
    chips: [
      {
        icon: TroubleshootRounded,
        label: "Analyse",
        position: { top: 24, right: 0, transform: "translateX(70%)" },
      },
      {
        icon: QuestionAnswerRounded,
        label: "Ask",
        position: { bottom: 32, left: 0, transform: "translateX(-20%)" },
      },
    ],
  },
  {
    title: "Craft Reports and Documents Effortlessly",
    subtitle: "Compile well-researched reports that engineers would usually take weeks to complete",
    image: "/our_offerings/container_3.svg",
    chips: [
      {
        icon: AssignmentRounded,
        label: "Reports",
        position: { top: 24, right: 0, transform: "translateX(70%)" },
      },
      {
        icon: AlarmOnRounded,
        label: "Quick Turnaround",
        position: { bottom: 32, left: 0, transform: "translateX(-20%)" },
      },
    ],
  },
];

const usecases = [
  {
    key: 0,
    title: "Automated Permit Preparation",
    description:
      "Automatically compilation of all required documents, checklists, diagrams and safety procedures for any maintenance task.",
    image: AssignmentTurnedInRounded,
  },
  {
    key: 1,
    title: "Faster Troubleshooting",
    description:
      "Automate data collection, analysis and troubleshooting steps for DCS alerts to enable faster, consistent resolution across shifts.",
    image: NotificationsActiveRounded,
  },
  {
    key: 2,
    title: "Automated Reporting",
    description:
      "Generating comprehensive reports by automatically collecting and analyzing operational data.",
    image: AssignmentRounded,
  },
  {
    key: 3,
    title: "New Hire Training",
    description:
      "Accelerating onboarding by providing contextual guidance and systematic knowledge transfer.",
    image: SchoolRounded,
  },
  {
    key: 4,
    title: "Easy RCA Preparation",
    description:
      "Automate data collection, analysis and documentation steps in root cause analysis to accelerate incident investigations.",
    image: FindInPageRounded,
  },
  {
    key: 5,
    title: "Understand P&IDs",
    description:
      "Understand, ask questions and generate markups on PFD, P&ID diagrams for equipment details and process flows.",
    image: SchemaRounded,
  },
];

export default function Home() {
  const { handleOpenForm } = useFormProvider();

  return (
    <Box>
      <Hero
        title1={"AI Assistants for"}
        title2={"Manufacturing Operations"}
        subtitle1={
          "Raven builds AI assistants to automate routine plant workflows, enabling you to"
        }
        subtitle2={"focus on what matters most."}
      />
      <Benefits title={"The crisis today in Operations, Maintenance & Engineering"} />
      <OurOfferings features={features} />
      <Usecases usecases={usecases} />
      <IndustriesServed />
      <Testimonial />
      <WhyRaven />
      <HowItWorks />
      <Security />
      <CTA
        title={"Start with a Pilot Project"}
        subtitle={`Join industry leaders transforming their operations with Raven.`}
        onClick={handleOpenForm}
      />
    </Box>
  );
}
