import React, { useRef, useCallback } from "react";
import Container from "../Container";
import { Typography, Box, Card, IconButton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import {
  ArrowBack,
  ArrowForward,
  BuildCircleRounded,
  BusinessRounded,
  CheckCircleRounded,
  ErrorOutlineRounded,
} from "@mui/icons-material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { makeStyles } from "tss-react/mui";
import { GradientIconLight } from "../GradientIcon";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    textAlign: "center",
    background: "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

export const testimonials = [
  {
    key: 0,
    name: "- Operations Director",
    role: "Fertilizer Plant of a Leading Global Manufacturer",
    testimonial:
      "Raven's AI assistant has transformed our permit preparation process, making it significantly faster and more reliable. The automated system ensures our team follows all safety procedures and requirements while reducing manual effort. We're now expanding our use of Raven to streamline other critical workflows across our operations.",
  },
];

const results = [
  {
    title: "The Client",
    description:
      "A global chemical manufacturing company operating a leading fertilizer facility in Sub-Saharan Africa.",
    icon: BusinessRounded,
    borderColor: "#F3E5F5",
  },
  {
    title: "Problem",
    description:
      "The company needed a faster and error free way to prepare work permits, which involved manually going through SOPs, P&IDs, equipment specifications, SOCs and historical records.",
    icon: ErrorOutlineRounded,
    borderColor: "#E1BEE7",
  },
  {
    title: "Solution",
    description:
      "Raven's AI assistant streamlined the permit preparation process by automatically finding relevant documents, identifying isolation points, providing safety procedures, checklists and more.",
    icon: BuildCircleRounded,
    borderColor: "#BA68C8",
  },
  {
    title: "Outcome",
    description:
      "The AI assistant significantly reduced permit preparation time while improving safety and compliance by ensuring all relevant procedures, specifications and requirements are properly followed.",
    icon: CheckCircleRounded,
    borderColor: "#9C27B0",
  },
];

function Testimonial() {
  const { classes } = useStyles();
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Container>
      <Typography variant="overline" sx={{ color: "#9C27B0" }}>
        Results
      </Typography>
      <Box mb={2} />
      <Typography variant="h4">Raven's Real World Impact</Typography>
      <Box mb={5} />
      <Box
        mb={5}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        gap={2.5}
      >
        {results.map((result) => {
          const { title, description, icon, borderColor } = result;
          return (
            <Box
              key={title}
              width={{ xs: "100%", md: 233 }}
              p={3}
              borderRadius={8}
              sx={{
                boxShadow: "2px 2px 10px 0px rgba(157, 157, 157, 0.16)",
                borderLeft: `4px solid ${borderColor}`,
              }}
            >
              <Box display={"flex"} alignItems={"center"} mb={{ xs: 2, md: 4 }}>
                <GradientIconLight Icon={icon} sx={{ fontSize: 32 }} />
                <Box mr={2} />
                <Typography variant="h5">{title}</Typography>
              </Box>
              <Typography variant="subtitle2" color={"textSecondary"}>
                {description}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Swiper
        ref={sliderRef}
        slidesPerView={1}
        spaceBetween={30}
        effect={"fade"}
        loop={true}
        className="testimonial-swiper"
        // autoplay={{
        //   delay: 2000,
        //   disableOnInteraction: false,
        // }}
        // navigation={true}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
      >
        {testimonials.map((t) => {
          const { key, name, role, testimonial } = t;
          return (
            <SwiperSlide key={key}>
              <Box
                component={Card}
                px={{ xs: 3, md: 10 }}
                pb={{ xs: 3, md: 4 }}
                borderRadius={8}
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  background: "linear-gradient(104deg, #FFF 1.1%, #FCF7FF 98.41%)",
                  boxShadow: "2px 2px 10px 0px rgba(169, 61, 253, 0.16)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                    zIndex: 0,
                  }}
                >
                  <FormatQuoteIcon
                    sx={{
                      transform: "rotate(180deg)",
                      fontSize: 200,
                      opacity: 0.1,
                      color: "rgba(169, 61, 253, 0.5)",
                    }}
                  />
                </Box>
                <Box position="relative" zIndex={1} mt={8}>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      typography: { xs: "body1", md: "h6" },
                    }}
                  >
                    {testimonial}
                  </Typography>
                  <Box mb={6} />
                  <Typography textAlign={"center"} sx={{ typography: { xs: "h6", md: "h6" } }}>
                    {name}
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    sx={{ typography: { xs: "subtitle2", md: "subtitle2" } }}
                    fontWeight={400}
                  >
                    {role}
                  </Typography>
                  <Box mb={4} />
                </Box>
              </Box>
            </SwiperSlide>
          );
        })}
        {/* <Box
            position={"absolute"}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ left: 0, bottom: { xs: 25, md: 45 }, zIndex: 1 }}
          >
            <IconButton onClick={handlePrev}>
              <ArrowBack sx={{ fontSize: 32 }} />
            </IconButton>
            <IconButton onClick={handleNext}>
              <ArrowForward sx={{ fontSize: 32 }} />
            </IconButton>
          </Box> */}
      </Swiper>
    </Container>
  );
}

export default Testimonial;
