import React from "react";
import { Typography, Box, Grid, useTheme } from "@mui/material";
import Container from "../Container";
import { GradientIconLight } from "../GradientIcon";
import QuickreplyRoundedIcon from "@mui/icons-material/QuickreplyRounded";
import SchemaRoundedIcon from "@mui/icons-material/SchemaRounded";
import RateReviewRoundedIcon from "@mui/icons-material/RateReviewRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import BugReportIcon from "@mui/icons-material/BugReport";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";

function Usecases({ usecases }) {
  const theme = useTheme();

  return (
    <Container>
      <Typography variant="overline" sx={{ color: "#9C27B0" }}>
        Use-cases
      </Typography>
      <Box mb={2} />
      <Typography variant="h4">Real-life Examples of Raven in Action</Typography>
      {/* <Box mb={2} />
      <Typography fontWeight={500}>
        How Industry Leaders Are Gaining an Edge with Raven AI Today
      </Typography> */}
      <Box mb={5} />
      <Grid container spacing={3}>
        {usecases.map((usecase) => {
          const { key, title, description, image } = usecase;
          return (
            <Grid item sm={12} md={4} key={key}>
              <Box
                height={300}
                // height={"100%"}
                display="flex"
                flexDirection="column"
                borderRadius={{ xs: 3, md: 8 }}
                p={3}
                sx={{
                  background: theme.palette.background.light,
                  // border: `1px solid ${theme.palette.accent}`,
                }}
              >
                <Box
                  sx={{
                    mr: 2,
                    background:
                      "linear-gradient(140deg, rgba(215, 213, 213, 0.08) 14.9%, rgba(255, 255, 255, 0.08) 38.71%, rgba(33, 36, 38, 0.08) 71.41%)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 48,
                    height: 48,
                    borderRadius: "50%",
                  }}
                >
                  <GradientIconLight Icon={image} sx={{ fontSize: 33 }} />
                </Box>
                <Box mb={1.5} />
                <Box overflow="hidden">
                  <Typography variant="h5">{title}</Typography>
                </Box>
                <Box mb={3} />
                <Box flex={1} overflow="hidden">
                  <Typography variant="subtitle2" color="textSecondary">
                    {description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default Usecases;
