import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Container from "../../components/Container";
import { makeStyles } from "tss-react/mui";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(311deg, #BF40AE 0%, #6B40BF 50%, #4E40BF 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function Blog() {
  const { classes } = useStyles();
  const { slug } = useParams();
  const navigate = useNavigate();
  const decodedSlug = decodeURIComponent(slug);

  useEffect(() => {
    if (
      !window.performance ||
      window.performance.getEntriesByType("navigation")[0].type !== "reload"
    ) {
      navigate(0); // Hard reloads the page
    }
  }, [navigate]);

  return null;
}

export default Blog;
