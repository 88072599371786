import React from "react";
import Container from "../../components/Container";
import { Box, Typography } from "@mui/material";

function OurStory() {
  return (
    <Container>
      <Typography sx={{ typography: { md: "h2", xs: "h3" } }}>OUR STORY</Typography>
      <Box mb={{ md: 6, xs: 5 }} />
      <Typography sx={{ typography: { xs: "body1", md: "h6" } }} color={"textSecondary"}>
        The story of Raven began in 2023 when we (Priyansh and Srivastava) returned home to
        celebrate our father's retirement. While we had spent years building scalable systems for
        leading startups and eventually becoming YC-backed founders ourselves (S22), our father was
        concluding his thirty-year journey through manufacturing operations at major companies like
        Reliance Industries, Petro Rabigh, Haldia Petrochemicals, and ONGC Petro additions Limited
        (OPaL).
        <br />
        <br />
        His reflections revealed a critical challenge in modern manufacturing: despite technological
        advances, organizations struggled with fragmented knowledge trapped in paper trails and
        scattered systems. This insight shaped our vision of building AI systems that could
        seamlessly integrate with and empower existing team structures, leading to the creation of
        Raven.
        <br />
        <br />
        We're a small but focused team based in San Francisco and Bangalore, combining our software
        expertise with real industry experience. Our goal isn't to build another enterprise
        platform, it's to create something that makes life easier for plant teams. Whether it's a
        night shift operator finding an emergency procedure or a maintenance engineer planning
        repairs, we want Raven to be their trusted companion.
        <br />
        <br />
        We see ourselves as partners to forward-thinking leaders in their quest for operational
        excellence. By combining our technology expertise with deep industry understanding, we found
        our purpose: creating solutions that truly understand and support the people who keep our
        manufacturing plants running.
      </Typography>
    </Container>
  );
}

export default OurStory;
