import React from "react";
import Container from "../../components/Container";
import { Typography, Box, IconButton, useMediaQuery, Grid, Button } from "@mui/material";
import {
  BoltRounded,
  ChevronRightRounded,
  DashboardRounded,
  PrecisionManufacturingRounded,
  TaskRounded,
} from "@mui/icons-material";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import { useTheme } from "@mui/material/styles";
import { GradientIconLight } from "../../components/GradientIcon";
import { makeStyles } from "tss-react/mui";
import { useNavigate } from "react-router-dom";

const data = [
  {
    title: "10-15% OEE Improvement",
    icon: PrecisionManufacturingRounded,
  },
  { title: "2%-10% Asset Uptime Improvement", icon: TrendingUpRoundedIcon },
  { title: "40% Faster Onboarding & Training", icon: SchoolRoundedIcon },
];

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function WhyRaven() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const { classes } = useStyles();
  const logos = [
    "/logo/y_combinator.png",
    "/logo/reliance.png",
    "/logo/opal.webp",
    "/logo/haldia.webp",
    "/logo/petro_rabigh.webp",
    "/logo/y_combinator.png",
    "/logo/reliance.png",
    "/logo/opal.webp",
    "/logo/haldia.webp",
    "/logo/petro_rabigh.webp",
    "/logo/y_combinator.png",
    "/logo/reliance.png",
    "/logo/opal.webp",
    "/logo/haldia.webp",
    "/logo/petro_rabigh.webp",
    "/logo/y_combinator.png",
    "/logo/reliance.png",
    "/logo/opal.webp",
    "/logo/haldia.webp",
    "/logo/petro_rabigh.webp",
  ];

  const teamMembers = [
    "/team/Priyansh.png",
    "/team/Sriyansh.png",
    "/team/ManojKumar.png",
    "/team/Suryansh.png",
    "/team/Rohith.png",
    "/team/Priyansh.png",
    "/team/Sriyansh.png",
    "/team/ManojKumar.png",
    "/team/Suryansh.png",
    "/team/Rohith.png",
  ];

  return (
    <Container>
      <Grid container height={"100%"} spacing={3} alignItems={"center"}>
        <Grid item xs={12} md={6} height={"100%"}>
          {/* <Typography variant="overline" sx={{ color: "#9C27B0" }}>
            Who we are
          </Typography>
          <Box mb={2} /> */}
          <Typography variant="h4">Why work with us</Typography>
          <Box mb={5} />
          <Typography>
            Raven, backed by <b>Y Combinator</b>, merges 30+ years of manufacturing expertise at{" "}
            <b>Reliance, Petro Rabigh, ONGC Petro Additives and Haldia Petrochemicals </b> with
            modern AI. Our team of former managers, engineers, shift leads & AI experts deeply
            understands plant operations and the AI solutions needed to overcome challenges.
          </Typography>
          <Box mb={5} />
          <Button
            variant="outlined"
            endIcon={<GradientIconLight Icon={ChevronRightRounded} sx={{ fontSize: 24 }} />}
            sx={{ color: theme.palette.accent, px: 2, py: 1 }}
            component="a"
            href="/about"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={classes.gradientText}>About Us</span>
          </Button>
        </Grid>
        {!isXS && (
          <Grid
            item
            xs={12}
            md={6}
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ paddingLeft: 40 }}
          >
            <Box
              pl={7.5}
              pr={2.5}
              sx={{
                height: 486,
                overflow: "hidden",
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "50px",
                  background: "linear-gradient(transparent, white)",
                  zIndex: 1,
                },
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  height: "50px",
                  background: "linear-gradient(white, transparent)",
                  zIndex: 1,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  animation: "scrollLogos 20s linear infinite",
                  "@keyframes scrollLogos": {
                    "0%": {
                      transform: "translateY(0)",
                    },
                    "100%": {
                      transform: "translateY(-33.33%)",
                    },
                  },
                }}
              >
                {[...logos].map((logo, index) => (
                  <img
                    key={`logo-${index}`}
                    src={logo}
                    alt="logo"
                    style={{ width: 91, marginTop: 10, marginBottom: 10 }}
                  />
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                height: 486,
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                padding: 1.5,
                overflow: "hidden",
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "50px",
                  background: "linear-gradient(transparent, white)",
                  zIndex: 1,
                },
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  height: "50px",
                  background: "linear-gradient(white, transparent)",
                  zIndex: 1,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderLeft: "8px dashed #9E9E9E",
                  borderRight: "8px dashed #9E9E9E",
                  px: 1.5,
                  gap: 4,
                  animation: "scrollLogos 35s linear infinite",
                  "@keyframes scrollLogos": {
                    "0%": {
                      transform: "translateY(0)",
                    },
                    "100%": {
                      transform: "translateY(-50%)",
                    },
                  },
                }}
              >
                {[...teamMembers].map((logo, index) => (
                  <img key={`logo-${index}`} src={logo} alt="logo" width={175} />
                ))}
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default WhyRaven;
