import React from "react";
import { Typography, Box, Link, useTheme, Card, Grid, Divider, Paper } from "@mui/material";
import { NavLink } from "react-router-dom";
import Container from "../Container";
import {
  FormatQuote as FormatQuoteIcon,
  Business as BusinessIcon,
  WarningAmber as WarningAmberIcon,
  Lightbulb as LightbulbIcon,
  ShowChart as ShowChartIcon,
  Security as SecurityIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import Testimonial, { testimonials } from "../Home/Testimonial";

const challenges = [
  "Critical safety procedures and requirements were scattered across multiple documents",
  "Teams needed to navigate 4-6 different systems to gather necessary information",
  "Inconsistent permit quality was observed across different shifts and teams",
  "There  was high reliance on experienced staff for routine permit preparation",
];

const results = [
  {
    title: "Reduced productivity:",
    description:
      "Engineers and operators spent 2-3 hours per day manually collecting and reviewing documentation",
  },
  {
    title: "Increased safety risks:",
    description:
      "Due to documentation gaps, missed steps and procedures and inaccuracies in implementation",
  },
  {
    title: "Compliance and audit challenges:",
    description: "Due to variance in process adherence and non-traceable audit trails",
  },
];

const approach = [
  {
    title: "Unified Information Access:",
    points: [
      "Connected all relevant systems and documents",
      "Created a single interface for accessing SOPs, P&IDs, equipment specifications, and historical records",
      "Implemented intelligent instant retrieval across all documentation",
    ],
  },
  {
    title: "Automated Permit Preparation:",
    points: [
      "AI-driven identification of required isolation points",
      "Automatic compilation of relevant safety procedures",
      "Smart generation of equipment-specific checklists",
      "Dynamic risk assessment based on task and location",
    ],
  },
  {
    title: "Knowledge Integration:",
    points: [
      "Incorporated best practices from experienced staff",
      "Built-in validation against safety requirements",
      "Real-time access to relevant historical incidents",
      "Contextual suggestions based on similar past permits",
    ],
  },
];

const features = [
  "Natural language interface for permit queries",
  "Automatic document compilation and verification",
  "Real-time safety requirement validation",
  "Integration with existing plant systems",
  "Mobile-friendly interface for field access",
  "Audit trail and compliance tracking",
];

const outcomesAndImpact = [
  {
    title: "Efficiency Gains",
    points: [
      "60% reduction in permit preparation time",
      "75% faster access to critical documentation",
      "40% reduction in senior staff involvement",
      "50% decrease in permit revision cycles",
    ],
  },
  {
    title: "Safety Improvements",
    points: [
      "Zero safety incidents related to permit documentation",
      "100% compliance with safety requirements",
      "Improved hazard identification",
      " Enhanced emergency response readiness",
    ],
  },
];

const lookingAhead = [
  "Planned expansion to other operational workflows",
  "Integration with additional plant systems",
  "Development of custom modules for specific processes",
  "Implementation across other facilities",
];

// Your existing data arrays remain the same

const MetricCard = ({ title, value }) => {
  const theme = useTheme();
  return (
    <Card
      elevation={0}
      sx={{
        p: 3,
        backgroundColor: theme.palette.grey[50],
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="body1" color="text.secondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" color="primary" fontWeight="bold">
        {value}
      </Typography>
    </Card>
  );
};

const SectionHeader = ({ icon: Icon, title }) => (
  <Box display="flex" alignItems="center" gap={1} mb={3}>
    <Icon color="primary" />
    <Typography variant="h4">{title}</Typography>
  </Box>
);

const testimonial = testimonials[0];

function Content() {
  const theme = useTheme();

  return (
    <Container maxWidth={1000}>
      {/* Client */}
      <Box mb={8}>
        <SectionHeader icon={BusinessIcon} title="The Client" />
        <Paper elevation={0} sx={{ p: 3, backgroundColor: theme.palette.grey[50] }}>
          <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
            A global chemical manufacturing company operating a leading fertilizer facility in
            Sub-Saharan Africa which produces nearly 3 million tonnes of fertilizer annually.
          </Typography>
        </Paper>
      </Box>

      {/* Challenge */}
      <Box mb={8}>
        <SectionHeader icon={WarningAmberIcon} title="The Challenge" />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                height: "100%",
                backgroundColor: theme.palette.error.lighter || "#ffebee",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Key Issues
              </Typography>
              <Box component="ul" sx={{ pl: 2 }}>
                {challenges.map((item, index) => (
                  <li key={index}>
                    <Typography variant="body1" sx={{ fontSize: "1.1rem", mb: 2 }}>
                      {item}
                    </Typography>
                  </li>
                ))}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                height: "100%",
                backgroundColor: theme.palette.warning.lighter || "#fff3e0",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Business Impact
              </Typography>
              <Box component="ul" sx={{ pl: 2 }}>
                {results.map((item, index) => (
                  <li key={index}>
                    <Typography sx={{ fontSize: "1.1rem", mb: 2 }}>
                      <strong>{item.title}</strong> {item.description}
                    </Typography>
                  </li>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Solution */}
      <Box mb={8}>
        <SectionHeader icon={LightbulbIcon} title="Raven's Approach" />
        <Grid container spacing={3}>
          {approach.map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  height: "100%",
                  backgroundColor: theme.palette.primary.lighter || "#e3f2fd",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {item.title}
                </Typography>
                <Box component="ul" sx={{ pl: 2 }}>
                  {item.points.map((point, index) => (
                    <li key={index}>
                      <Typography variant="body1" sx={{ fontSize: "1.1rem", mb: 2 }}>
                        {point}
                      </Typography>
                    </li>
                  ))}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Results */}
      <Box mt={2} mb={6}>
        <SectionHeader icon={ShowChartIcon} title="Results & Impact" />
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard title="Permit Preparation Time" value="↓60%" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard title="Documentation Access" value="↑75%" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard title="Senior Staff Time" value="↓40%" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard title="Safety Compliance" value="100%" />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {outcomesAndImpact.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper
                elevation={0}
                sx={{ p: 3, height: "100%", backgroundColor: theme.palette.grey[50] }}
              >
                <Typography variant="h6" gutterBottom>
                  {item.title}
                </Typography>
                <Box component="ul" sx={{ pl: 2 }}>
                  {item.points.map((point, index) => (
                    <li key={index}>
                      <Typography variant="body1" sx={{ fontSize: "1.1rem", mb: 2 }}>
                        {point}
                      </Typography>
                    </li>
                  ))}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        component={Card}
        px={{ xs: 3, md: 10 }}
        pb={{ xs: 3, md: 4 }}
        borderRadius={8}
        mb={10}
        sx={{
          position: "relative",
          overflow: "hidden",
          background: "linear-gradient(104deg, #FFF 1.1%, #FCF7FF 98.41%)",
          boxShadow: "2px 2px 10px 0px rgba(169, 61, 253, 0.16)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 0,
          }}
        >
          <FormatQuoteIcon
            sx={{
              transform: "rotate(180deg)",
              fontSize: 200,
              opacity: 0.1,
              color: "rgba(169, 61, 253, 0.5)",
            }}
          />
        </Box>
        <Box position="relative" zIndex={1} mt={8}>
          <Typography
            variant="h5"
            textAlign="center"
            gutterBottom
            sx={{
              fontWeight: 600,
              mb: 4,
              background: "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            What Our Customers Say
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{
              typography: { xs: "body1", md: "h6" },
            }}
          >
            {testimonial.testimonial}
          </Typography>
          <Box mb={6} />
          <Typography textAlign={"center"} sx={{ typography: { xs: "h6", md: "h6" } }}>
            {testimonial.name}
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{ typography: { xs: "subtitle2", md: "subtitle2" } }}
            fontWeight={400}
          >
            {/* {testimonial.role} */}
          </Typography>
          <Box mb={4} />
        </Box>
      </Box>

      {/* Future Expansion Plans */}
      <Box mb={8} sx={{ width: "100%" }}>
        <SectionHeader icon={ArrowForwardIcon} title="Future Expansion Plans" />
        <Paper
          elevation={0}
          sx={{
            p: 3,
            backgroundColor: theme.palette.success.lighter || "#e8f5e9",
            width: "100%",
          }}
        >
          <Box component="ul" sx={{ pl: 2 }}>
            {lookingAhead.map((item, index) => (
              <li key={index}>
                <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
                  {item}
                </Typography>
              </li>
            ))}
          </Box>
        </Paper>
      </Box>

      {/* About */}
      <Box>
        <Typography variant="h4" gutterBottom>
          About Raven
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
          Raven creates AI assistants that help manufacturing plants operate more efficiently and
          safely. Our YC-backed team combines manufacturing expertise with advanced AI to improve
          plant operations.{" "}
          <Link
            color="secondary"
            component={NavLink}
            to="/about"
            sx={{
              "&:hover": {
                color: theme.palette.primary.main,
              },
              fontSize: "1.1rem",
            }}
          >
            Know more about us here
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}

export default Content;
