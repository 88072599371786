import React, { useCallback, useState, useRef } from "react";
import { Box, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import Container from "../Container";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  industriesSwiper: {
    width: "100%",
    ".swiper-pagination": {
      //   bottom: -100,
      background: "white",
      ".swiper-pagination-bullet": {
        background: "rgba(0, 0, 0, 0.18)",
      },
    },
  },
}));

const industries = [
  { name: "Chemicals", image: "/industries/chemicals.webp" },
  { name: "Fertilizers", image: "/industries/fertilizers.webp" },
  {
    name: "Petrochemicals",
    image: "/industries/petrochemicals.webp",
  },
  { name: "Oil & Gas", image: "/industries/oil_gas.webp" },
  { name: "Energy", image: "/industries/energy.webp" },
  { name: "Utilities", image: "/industries/utilities.webp" },
  { name: "EPC", image: "/industries/epc.webp" },
];

function IndustriesServed() {
  const theme = useTheme();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSM = useMediaQuery((theme) => theme.breakpoints.down(1150));
  const { classes } = useStyles();
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Container>
      <Typography variant="overline" sx={{ color: "#9C27B0" }}>
        Industries Served
      </Typography>
      <Box mb={2} />
      <Typography variant="h4">We Offer Solutions for</Typography>
      <Box mb={5} />
      <Swiper
        className={classes.industriesSwiper}
        onActiveIndexChange={(swiper) => {
          setCurrentIndex(swiper.activeIndex);
        }}
        ref={sliderRef}
        slidesPerView={isXS ? 1 : 3}
        spaceBetween={20}
        modules={[Autoplay]}
      >
        {industries.map((industry, index) => {
          const { name, image } = industry;
          return (
            <SwiperSlide key={index}>
              <Box borderRadius={4} position={"relative"} display={"inline-block"}>
                <img
                  src={image}
                  alt={name}
                  width={isXS ? "100%" : isSM ? 200 : 300}
                  style={{ borderRadius: 16 }}
                />

                <Typography
                  typography={{ xs: "h6", md: "h5" }}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "white",
                    borderRadius: 2,
                    padding: "4px 12px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {name}
                </Typography>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Box my={5} width={"100%"} display={"flex"} justifyContent={"center"} gap={2.5}>
        <IconButton
          onClick={handlePrev}
          size="small"
          sx={{
            background: "#FFF",
            boxShadow:
              "0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20)",
          }}
        >
          <ArrowBackIosRounded sx={{ fontSize: 16 }} />
        </IconButton>
        <IconButton
          onClick={handleNext}
          size="small"
          sx={{
            background: "#FFF",
            boxShadow:
              "0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20)",
          }}
        >
          <ArrowForwardIosRounded sx={{ fontSize: 16 }} />
        </IconButton>
      </Box>
      <Box width={"100%"} display={"flex"} justifyContent={"center"} gap={1}>
        {industries.map((industry, index) => {
          return (
            <Box
              key={index}
              width={currentIndex === index ? 40 : 12}
              height={8}
              borderRadius={1}
              bgcolor={currentIndex === index ? "#9C27B0" : "rgba(0, 0, 0, 0.18)"}
              sx={{
                transition: "all 0.3s ease-in-out",
              }}
            />
          );
        })}
      </Box>
    </Container>
  );
}

export default IndustriesServed;
